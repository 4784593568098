<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Add Product</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <vField v-model="formData.product_head_id" class="d-none" name="product_head_id"/>
                        <div class="row gy-2 px-2">
                            <div class="col-md-12 px-2">
                                <label class="form-label" for="select2-basic">Product & Service Group</label>
                                <v-select
                                    v-model="formData.product_head_id"
                                    :options="group" 
                                    label="name"
                                    :reduce="name => name.id"
                                />
                                <ErrorMessage name="product_head_id" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="name">Name</label>
                                <vField
                                    v-model="formData.name"
                                    name="name"
                                    type="text"
                                    id="name"
                                    class="form-control add-credit-card-mask" 
                                    placeholder="Name"
                                />
                                <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                            </div>

                            <div v-if="formData.image" class="col-12">
                                <div class="d-flex">
                                    <BlobImageViewer
                                        :file="formData.image"
                                    />
                                </div>
                            </div>

                            <div class="col-12">
                                <label for="formFile" class="form-label">Select Primary Image</label>
                                <input @change="onUploadPrimaryPhoto" class="form-control" type="file" id="formFile">
                            </div>

                            <div class="col-12 text-center">
                                <div class="d-flex flex-wrap justify-content-between">
                                    <BlobImageViewer
                                        v-for="(file, index) in formData.additional_images"
                                        :file="file"
                                        :key="index"
                                        :index="index"
                                    >
                                        <template v-slot:handler> 
                                            <img
                                                @click="removeImage(index)"
                                                class="position-absolute cursor-pointer top-0" 
                                                width="15" 
                                                src="/app-assets/images/icons/trash.svg" 
                                                alt=""
                                            >
                                        </template> 
                                    </BlobImageViewer>
                                </div>
                                <div>
                                    <div v-bind="getRootProps()">
                                    <input v-bind="getInputProps()" >
                                    <p v-if="isDragActive">Drop the files here ...</p>
                                    <p v-else>Drag 'n' drop some additional images</p>
                                    </div>
                                    <button @click.prevent="open">open</button>
                                </div>
                            </div>

                            <div class="col-md-12 px-2">
                                <vField v-model="formData.unit_id" class="d-none" name="unit_id"/>
                                <label class="form-label" for="select2-basic">Unit</label>
                                <v-select 
                                    v-model="formData.unit_id"
                                    :options="units" 
                                    label="name"
                                    :reduce="name => name.id"
                                />
                                <ErrorMessage name="unit_id" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-md-12 px-2">
                                <label class="form-label" for="select2-basic">Add Specifications</label>
                                <v-select
                                    @option:deselecting="onDeselectSpec"
                                    multiple
                                    v-model="selectedSpecifications"
                                    :options="specificationArr" 
                                    label="name"
                                />
                            </div>

                            <div class="col-md-12 px-2">
                                <table>
                                    <BrandValue 
                                        v-for="(name, i) in selectedSpecifications"
                                        :setBrandValue="setBrandValue"
                                        :key="i"
                                        :name="name"
                                    />
                                </table>
                            </div>

                            <div class="col-12">
                                <label class="form-label" for="name">Short Description</label>
                                <vField
                                    as="textarea"
                                    v-model="formData.short_description"
                                    name="short_description"
                                    id="Short"
                                    class="form-control add-credit-card-mask" 
                                    placeholder="Short"
                                />
                                <ErrorMessage name="short_description" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="Full">Full Description</label>
                                <vField
                                    as="textarea"
                                    v-model="formData.full_description"
                                    name="full_description"
                                    id="Full"
                                    class="form-control add-credit-card-mask" 
                                    placeholder="Full"
                                />
                                <ErrorMessage name="full_description" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="purchase_price">Purchase Price</label>
                                <vField
                                    type="text"
                                    v-model="formData.purchase_price"
                                    name="purchase_price"
                                    id="purchase_price"
                                    class="form-control add-credit-card-mask text-end" 
                                    placeholder="Purchase price"
                                />
                                <ErrorMessage name="purchase_price" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-md-12 px-2">
                                <label class="form-label" for="select2-basic">Purchase Account</label>
                                <vField type="text" name="purchase_account_head_id" class="d-none" v-model="formData.purchase_account_head_id" />
                                <v-select
                                    v-model="formData.purchase_account_head_id"
                                    :options="purchaseHead" 
                                    label="name"
                                    :reduce="name => name.id"
                                />
                                <ErrorMessage name="purchase_account_head_id" class="invalid-feedback d-block qcont"/>
                            </div>

                            <div class="col-12">
                                <label class="form-label" for="sales_price">Sales Price</label>
                                <vField
                                    type="text"
                                    v-model="formData.sales_price"
                                    name="sales_price"
                                    id="sales_price"
                                    class="form-control add-credit-card-mask text-end" 
                                    placeholder="purchase price"
                                />
                                 <ErrorMessage name="sales_price" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-md-12 px-2">
                                <vField type="text" name="sales_account_head_id" class="d-none" v-model="formData.sales_account_head_id" />
                                <label class="form-label">Sales Account</label>
                                <v-select
                                    v-model="formData.sales_account_head_id"
                                    :options="salesHead" 
                                    label="name"
                                    name="sales_account_head_id"
                                    :reduce="name => name.id"
                                />
                                <ErrorMessage name="sales_account_head_id" class="invalid-feedback d-block qcont"/>
                            </div>

                            <div class="col-12">
                                <label class="form-label" for="cost_of_sales">Cost of sales</label>
                                <vField
                                    type="text"
                                    v-model="formData.cost_of_sales"
                                    name="cost_of_sales"
                                    id="cost_of_sales"
                                    class="form-control add-credit-card-mask text-end" 
                                    placeholder="Cost of sales"
                                />
                                <ErrorMessage name="cost_of_sales" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-md-12 px-2">
                                <vField type="text" name="cost_of_sales_account_head_id" class="d-none" v-model="formData.cost_of_sales_account_head_id" />
                                <label class="form-label" for="cost_of_sales_account_head_id">Cost of sales account</label>
                                <v-select
                                    v-model="formData.cost_of_sales_account_head_id"
                                    :options="costHead" 
                                    label="name"
                                    name="cost_of_sales_account_head_id"
                                    :reduce="name => name.id"
                                />
                                <ErrorMessage name="cost_of_sales_account_head_id" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-md-12 px-2">
                                <vField type="text" name="inventory_account_head_id" class="d-none" v-model="formData.inventory_account_head_id" />
                                <label class="form-label" for="select2-basic">Inventory account</label>
                                <v-select
                                    v-model="formData.inventory_account_head_id"
                                    :options="inventoryHead" 
                                    label="name"
                                    :reduce="name => name.id"
                                />
                                <ErrorMessage name="inventory_account_head_id" class="invalid-feedback d-block qcont"/>
                            </div>

                            <div class="col-6">
                                <label class="form-label" for="vat_rate">VAT Rate (%)</label>
                                <vField
                                    type="text"
                                    v-model="formData.vat_rate"
                                    name="vat_rate"
                                    id="vat_rate"
                                    class="form-control add-credit-card-mask text-end" 
                                    placeholder="VAT Rate"
                                />
                                <ErrorMessage name="vat_rate" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-6">
                                <label class="form-label" for="SKU">SKU</label>
                                <vField
                                    type="text"
                                    v-model="formData.sku"
                                    name="SKU"
                                    id="SKU"
                                    class="form-control add-credit-card-mask" 
                                    placeholder="SKU"
                                />
                            </div>

                          <div class="col-md-12 px-2">
                            <vField type="text" name="inventory_method" class="d-none" v-model="formData.inventory_method" />
                            <label class="form-label" for="select2-basic">Inventory method</label>
                            <v-select
                                v-model="formData.inventory_method"
                                :options="inventoryMethods"
                                label="name"
                                :reduce="name => name.id"
                            />
                          </div>
                        </div>
                       
                        
                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Submit
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useDropzone } from 'vue3-dropzone'
import BlobImageViewer from '@/components/atom/BlobImageViewer'
import BrandValue from './BrandValue'

export default {
    name: 'ProductAddModal',

    components: {
        BlobImageViewer,
        BrandValue
    },

    data: () => ({
        isOpen: false,
        loading: false,
        schema: {
            name: 'required|min:3|max:150',
            product_head_id: 'required',
            unit_id: 'required',
            short_description: 'min:3|max:550',
            full_description: 'min:3|max:1550',
            purchase_price: 'numeric',
            sales_price: 'numeric',
            vat_rate: 'numeric',
            purchase_account_head_id: 'required',
            sales_account_head_id: 'required',
            cost_of_sales_account_head_id: 'required',
            inventory_account_head_id: 'required',
        }
    }),

    computed: {
        specificationArr () {
            return Object.keys(this.specifications)
        },
        purchaseHead () {
            return this.accountHeads.filter(i => i.account_slug == 'purchase_accounts')
        },
        salesHead () {
            return this.accountHeads.filter(i => i.account_slug == 'sales_accounts')
        },
        costHead () {
            return this.accountHeads.filter(i => i.account_slug == 'cost_of_sales')
        },
        inventoryHead () {
            return this.accountHeads.filter(i => i.account_slug == 'inventory_assets')
        },

    },


    methods: {
        submit() {
            this.$emit('onClickSubmitProduct')
        },
        toggleModal () {
            if(this.isOpen) {
                this.$emit('onCloseModal')
            }
            this.isOpen = !this.isOpen
        },
        onDeselectSpec (item) {
           this.removeBrandValue(item)
        },
        removeImage (index) {
            this.formData.additional_images.splice(index, 1)
        },
        onUploadPrimaryPhoto(event) {
            this.formData.image = event.target.files[0]
        }
    },

    props: {
        formData: Object,
        accountHeads: Array,
        clue: Object,
        units: Array,
        group: Array,
        specifications: Object,
        setBrandValue: Function,
        removeBrandValue: Function,
        selectedSpecifications: Array,
        inventoryMethods: Array,
    },

    setup(props) {
        function onDrop(acceptFiles, rejectReasons) {
            props.formData.additional_images = [...props.formData.additional_images, ...acceptFiles]
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })

        return {
            getRootProps,
            getInputProps,
            ...rest
        }
    }
}
</script>
